import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import Preloader from './components/Preloader';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import Contact from './components/Contact';
import ChatSystem from './components/ChatSystem';
import Footer from './components/Footer';
import FloatingIcons from './components/FloatingIcons';

import './App.css';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 100,
    });
  }, []);

  return (
    <>
      <Preloader />
      <div className="spline-container">
        <iframe
          src="https://my.spline.design/chips-1f3cdb0a723efcc189a3c64a56bf3023/"
          frameBorder="0"
          width="100%"
          height="100%"
          title="spline"
        />
      </div>
      <div className="content-wrapper">
        <Navbar />
        <Hero />
        <Services />
        <Contact />
        <ChatSystem />
        <Footer />
        <FloatingIcons />
      </div>
    </>
  );
}

export default App;