import React from "react";
import "./Footer.css";

import euro1 from "../assets/euro1.png";
import euro2 from "../assets/euro2.png";
import euro3 from "../assets/euro3.png";
import euro4 from "../assets/euro4.png";
import pdfFile from "../assets/DIGITALIZARE GRADATT.pdf";

const Footer = () => {
  const handleInfoClick = () => {
    window.open(pdfFile, "_blank"); // Am adăugat '_blank' pentru a deschide într-o filă nouă
  };

  return (
    <footer className="footer-section">
      

      <div className="container">
        <div className="footer-content">
          <div className="footer-company-info">
            <a href="/" className="footer-logo">
              <img
                src="https://gradat.ro/wp-content/uploads/2024/03/logo.svg"
                alt="GRADAT Logo"
              />
            </a>
            <p className="company-description">
              Oferim soluții tehnice inovatoare în construcții și inginerie
              mecanică. Cu expertiza noastră și devotamentul pentru calitate,
              transformăm viziunea în realitate pentru fiecare proiect.
            </p>
          </div>

          <div className="footer-column">
            <h4>Link-uri Rapide</h4>
            <ul className="footer-links">
              <li>
                <a href="#">Despre Noi</a>
              </li>
              <li>
                <a href="#">Servicii</a>
              </li>
              <li>
                <a href="#">Proiecte</a>
              </li>
              <li>
                <a href="#">Contact</a>
              </li>
            </ul>
          </div>

          <div className="footer-column">
            <h4>Servicii</h4>
            <ul className="footer-links">
              <li>
                <a href="#">Materiale de Construcții</a>
              </li>
              <li>
                <a href="#">Echipamente Sanitare</a>
              </li>
              <li>
                <a href="#">Consultanță</a>
              </li>
              <li>
                <a href="#">Livrare</a>
              </li>
            </ul>
          </div>

          <div className="footer-column">
            <h4>Newsletter</h4>
            <div className="footer-newsletter">
              <p>Abonează-te pentru noutăți și oferte speciale</p>
              <form className="newsletter-form">
                <input
                  type="email"
                  className="newsletter-input"
                  placeholder="Email-ul tău"
                />
                <button type="submit" className="newsletter-btn">
                  Abonare
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="eu-funding-section">
        <div className="container">
          <div className="funding-content">
            <p className="funding-text">
              Pentru informații detaliate despre celelalte programe cofinanțate
              de Uniunea Europeană, vă invităm să vizitați{" "}
              <a href="https://www.mfe.gov.ro" className="funding-link">
                www.mfe.gov.ro
              </a>
            </p>
            <div className="links-and-button">
              <div className="funding-links">
                <a href="http://www.regionordest.ro" className="funding-link">
                  www.regionordest.ro
                </a>
                <a
                  href="https://www.facebook.com/Regio.NordEst.ro"
                  className="funding-link"
                >
                  www.facebook.com/Regio.NordEst.ro
                </a>
              </div>
              <div className="aproape-button">Aproape de tine</div>
            </div>
          </div>
        </div>
      </div>

          <div className="footer-banner">
            <div className="logo-row">
              <img src={euro1} alt="Euro Logo 1" className="banner-logo" />
              <img src={euro2} alt="Euro Logo 2" className="banner-logo" />
              <img src={euro3} alt="Euro Logo 3" className="banner-logo" />
              <img src={euro4} alt="Euro Logo 4" className="banner-logo" />
            </div>
            <button onClick={handleInfoClick} className="info-button">
              Proiect digitalizare - Află mai multe
            </button>
          </div>

      <div className="footer-bottom">
        <div className="container">
          <p>© 2024 GRADAT. Toate drepturile rezervate.</p>
        </div>
      </div>

      
    </footer>
  );
};

export default Footer;
