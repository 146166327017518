import React, { useEffect } from 'react';
import './Navbar.css';

const Navbar = () => {
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");
      if (window.scrollY > 50) {
        navbar.style.background = "rgba(10, 25, 41, 0.98)";
      } else {
        navbar.style.background = "rgba(10, 25, 41, 0.95)";
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav className="navbar">
      <div className="container">
        <a href="/" className="navbar-brand">
          <img src="https://gradat.ro/wp-content/uploads/2024/03/logo.svg" alt="GRADAT Logo" />
        </a>
        <div className="nav-outer ms-lg-auto">
          <a href="https://dashboard.gradatt.com" className="theme-btn">LOGIN</a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;