import React from 'react';
import './FloatingIcons.css';

const FloatingIcons = () => {
  return (
    <div className="fixed-icons">
      <a href="https://wa.me/4731367222" target="_blank" rel="noopener noreferrer">
        <img
          src="https://img.icons8.com/?size=100&id=16713&format=png&color=000000"
          alt="WhatsApp"
        />
      </a>
      <a href="tel:+4731367222">
        <img
          src="https://img.icons8.com/?size=100&id=I24lanX6Nq71&format=png&color=000000"
          alt="Phone"
        />
      </a>
    </div>
  );
};

export default FloatingIcons;