import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section className="hero-section">
      <div className="container">
        <div className="hero-content" data-aos="fade-up">
          <h1 data-text="Soluții tehnice inovatoare în construcții">
            Soluții tehnice inovatoare în construcții
          </h1>
          <div className="project-info">
            <p><strong>Oferim soluții tehnice inovatoare</strong> în domeniul prelucrării materialelor și ingineriei mecanice. 
            Cu un angajament solid pentru excelență și performanță, compania noastră se remarcă prin 
            calitatea serviciilor oferite și adaptabilitatea la cerințele fiecărui proiect.</p>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;