import React from 'react';
import './Contact.css';

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
  };

  return (
    <section id="contact" className="contact-section">
      <div className="container">
        <h2 className="text-center">Contact</h2>
        
        <div className="contact-grid">
          <div className="contact-info">
            <div className="contact-item">
              <h5>Adresă</h5>
              <p>Calea Chisinaului, Nr. 23, Bloc C1, Ap. Corp C1, Iași, Județ Iași<br/>București</p>
            </div>
            
            <div className="contact-item">
              <h5>Email</h5>
              <a href="mailto:gabriela.nica@avatti.ro">gabriela.nica@avatti.ro</a>
            </div>
            
            <div className="contact-item">
              <h5>Telefon</h5>
              <a href="tel:+4731367222">0731367222</a>
            </div>
            
            <div className="contact-item">
              <h5>Social Media</h5>
              <div className="social-links">
                <a href="https://www.facebook.com/profile.php?id=61569527732105" target="https://www.facebook.com/profile.php?id=61569527732105" rel="noopener noreferrer">
                  <i className="fab fa-facebook"></i> Facebook
                </a>
                <a href="https://www.linkedin.com/in/gradat-b81b7633a/" target="https://www.linkedin.com/in/gradat-b81b7633a/" rel="noopener noreferrer">
                  <i className="fab fa-linkedin"></i> LinkedIn
                </a>
              </div>
            </div>
            
            <div className="map-container">
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2718.721583186641!2d27.6000752!3d47.1513579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cafbf8dddd56f9%3A0x0!2sCalea%20Chisinaului%2C%20Nr.%2023%2C%20Bloc%20C1%2C%20Ap.%20Corp%20C1%2C%20Ia%C8%99i%2C%20Jude%C8%9B%20Ia%C8%99i!5e0!3m2!1sen!2sro!4v1690000000000!5m2!1sen!2sro"
                title="location"
                allowFullScreen=""
                loading="lazy">
              </iframe>
            </div>
          </div>
          
          <div className="contact-form">
            <h3>Trimite-ne un mesaj</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Numele tău" />
              </div>
              <div className="form-group">
                <input type="email" className="form-control" placeholder="Email-ul tău" />
              </div>
              <div className="form-group">
                <textarea className="form-control" placeholder="Mesajul tău"></textarea>
              </div>
              <button type="submit" className="contact-btn">Trimite Mesaj</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;