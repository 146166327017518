import React, { useEffect } from 'react';
import './Preloader.css';

const Preloader = () => {
  useEffect(() => {
    const handleLoad = () => {
      document.querySelector(".preloader").style.display = "none";
    };

    window.addEventListener("load", handleLoad);
    return () => window.removeEventListener("load", handleLoad);
  }, []);

  return (
    <div className="preloader">
      <div className="custom-loader"></div>
    </div>
  );
};

export default Preloader;