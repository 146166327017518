import React from 'react';
import './Services.css';

const Services = () => {
  const services = [
    {
      icon: "fas fa-building",
      title: "Comerț cu ridicata al materialelor de construcții",
      description: "Oferim o gamă completă de materiale de construcții de înaltă calitate",
      delay: 100,
      colSize: 4
    },
    {
      icon: "fas fa-shower",
      title: "Distribuție de echipamente sanitare",
      description: "Echipamente sanitare moderne și accesorii pentru orice tip de proiect",
      delay: 200,
      colSize: 4
    },
    {
      icon: "fas fa-tree",
      title: "Furnizare de lemn pentru construcții",
      description: "Materiale lemnoase de calitate pentru construcții și finisaje",
      delay: 300,
      colSize: 4
    },
    {
      icon: "fas fa-comments",
      title: "Consultanță în alegerea materialelor",
      description: "Expertiză în selectarea materialelor potrivite pentru proiectul dumneavoastră",
      delay: 400,
      colSize: 6
    },
    {
      icon: "fas fa-truck",
      title: "Livrare și suport logistic",
      description: "Servicii complete de livrare și suport logistic pentru materiale de construcții",
      delay: 500,
      colSize: 6
    }
  ];

  return (
    <section className="services-section">
      <div className="container">
        <h2 className="text-center mb-5" data-aos="fade-up">Serviciile Noastre</h2>
        <div className="row g-4">
          {services.map((service, index) => (
            <div 
              key={index} 
              className={`col-lg-${service.colSize}`}
              // data-aos="fade-up" 
              data-aos-delay={service.delay}
            >
              <div className="service-card">
                <i className={`${service.icon} service-icon`}></i>
                <h4>{service.title}</h4>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;